import {io} from "socket.io-client";
import {v4 as uuidv4} from "uuid";
import router from "@/router/router";
import Vue from "vue";
import i18n from "@/i18n";

const store = {
    isConnected: false,
    team: null,

    createTeam: function (organizerEmail, organizerLocale, name, organizerName) {
        _emit("client:create-team", {organizerEmail, organizerLocale, name, organizerName});
    },

    loadTeam: function (path) {
        _emit("client:load-team", {path});
    },

    leaveTeam: function () {
        this.team = null;
        _emit("client:leave-team");
    },

    _serverLoadedTeam: function (team) {
        this.team = team;

        const routeParams = {name: "Team", params: {locale: i18n.locale, path: team.path}};
        const newHref = router.resolve(routeParams).href;

        if (newHref === router.currentRoute.path) {
            return;
        }

        // See https://github.com/vuejs/vue-router/issues/2872#issuecomment-519073998
        router.replace(routeParams).catch(() => {
        });
    },

    addMember: function (name, email, locale) {
        const member = {
            availabilities: {},
            email,
            id: uuidv4(),
            locale,
            name
        };

        this.team.members.push(member);

        // Update 2021-07-14, after forgetting this line in the 2021-07-01 update:
        // CD and AP don't want the list to be sorted ;)
        // this.team.members.sort((a, b) => a.name.localeCompare(b.name));

        _emit("client:add-member", {
            member,
            teamId: this.team.id
        });
    },

    updateMemberData: function (member, name, email) {
        this._doUpdateMemberData(member, name, email);

        _emit("client:update-member-data", {
            email,
            id: member.id,
            name,
            teamId: this.team.id
        });
    },

    _serverUpdatedMemberData: function (id, name, email) {
        const index = this.team.members.findIndex(member => member.id === id);

        if (index === -1) {
            return;
        }

        this._doUpdateMemberData(this.team.members[index], name, email);
    },

    _doUpdateMemberData: function (member, name, email) {
        member.name = name;
        member.email = email;
    },

    removeMember: function (id) {
        this._doRemoveMember(id);

        _emit("client:remove-member", {
            id,
            teamId: this.team.id
        });
    },

    updateMemberAvailability: function (member, dayKey, availability) {
        const previousAvailability = member.availabilities[dayKey];

        this._doUpdateMemberAvailability(member, dayKey, availability);

        _emit("client:update-availability", {
            id: member.id,
            dayKey,
            availability,
            teamId: this.team.id
        });
    },

    _serverUpdatedMemberAvailability: function (id, dayKey, availability) {
        const index = this.team.members.findIndex(member => member.id === id);

        if (index === -1) {
            return;
        }

        this._doUpdateMemberAvailability(this.team.members[index], dayKey, availability);
    },

    _doUpdateMemberAvailability: function (member, dayKey, availability) {
        if (availability) {
            Vue.set(member.availabilities, dayKey, availability);
        } else {
            Vue.delete(member.availabilities, dayKey);
        }
    },

    _serverRemovedMember: function (id) {
        this._doRemoveMember(id);
    },

    _doRemoveMember: function (id) {
        const index = this.team.members.findIndex(member => member.id === id);

        if (index === -1) {
            return;
        }

        this.team.members.splice(index, 1);
    },

    _serverAddedMember: function (member) {
        this.team.members.push(member);
    },

    _connected() {
        this.isConnected = true;
    },

    _disconnected() {
        this.isConnected = false;
    }
};

function _connectToServer() {
    if (process.env.NODE_ENV === "development") {
        // console.debug("Store: Connecting (dev)");
        return io.connect(":3303");
    }

    // console.debug("Store: Connecting (prod)");
    return io.connect();
}

const socket = _connectToServer();

function _emit(eventName, args = {}) {
    // console.debug("Store: Sending " + eventName, args);
    socket.emit(eventName, args);
}

socket.on("connect", () => {
    // console.debug(`Store: Connected (ID ${socket.id})`);
    store._connected();
});

socket.on("disconnect", () => {
    // console.debug("Store: Disconnected");
    store._disconnected();
});

socket.onAny((eventName, ...args) => {
    // console.debug("Store: Received " + eventName, args);
});

socket.on("server:load-team", (event) => {
    store._serverLoadedTeam(event.team);
});

socket.on("server:add-member", (event) => {
    if (event.sender === socket.id) {
        return;
    }

    store._serverAddedMember(event.member);
});

socket.on("server:update-availability", (event) => {
    if (event.sender === socket.id) {
        return;
    }

    store._serverUpdatedMemberAvailability(event.id, event.dayKey, event.availability);
});

socket.on("server:remove-member", (event) => {
    if (event.sender === socket.id) {
        return;
    }

    store._serverRemovedMember(event.id);
});

socket.on("server:update-member-data", (event) => {
    if (event.sender === socket.id) {
        return;
    }

    store._serverUpdatedMemberData(event.id, event.name, event.email);
});

socket.on("server:unknown-team", (event) => {
    // console.debug(`Store: Server signals unknown team path "${event.path}"`);
});

export default store;
