import Vue from "vue";
import VueRouter from "vue-router";

import i18n from "@/i18n";
import Create from "@/views/Create.vue";
import DataProtection from "@/views/DataProtection";
import Home from "@/views/Home.vue";
import Imprint from "@/views/Imprint";
import Root from "@/router/Root";
import Team from "@/views/Team";
import {setLocale} from "@/i18n/set-locale";
import {getStartingLocale} from "@/i18n/get-starting-locale";
import {isSupportedLocale} from "@/i18n/is-supported-locale";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: i18n.locale
    },
    {
        path: "/:locale",
        component: Root,
        children: [
            {
                path: "",
                name: "Home",
                component: Home
            },
            {
                path: "create",
                name: "Create",
                component: Create
            },
            {
                path: "team/:path",
                name: "Team",
                component: Team
            },
            {
                path: "imprint",
                name: "Imprint",
                component: Imprint
            },
            {
                path: "data-protection",
                name: "DataProtection",
                component: DataProtection
            },
        ]
    },
];

const router = new VueRouter({
    routes
});

// See https://github.com/PhraseApp-Blog/vue-i18n-demo/blob/master/src/router/index.js

router.beforeEach((to, from, next) => {
    // console.debug("Router: Before switch from " + (from.name || "n/a") + " with locale " + (from.params.locale || "n/a") + " to " + to.name + " with locale " + to.params.locale);

    if (to.params.locale === from.params.locale) {
        // console.debug("Router: Proceed");
        next();
        return;
    }

    if (!isSupportedLocale(to.params.locale)) {
        // console.debug("Router: Locale " + to.params.locale + " is not supported - updating to default locale " + getStartingLocale());
        next({name: to.name, params: {...to.params, locale: getStartingLocale()}});
        return;
    }

    // console.debug("Router: Setting locale " + to.params.locale);
    setLocale(to);

    // console.debug("Router: Proceed");
    next();
});

export default router;
