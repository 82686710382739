export function pageTitleMetaInfo(pageTitle, index = true) {
    const robots = [{ name: "robots", content: index ? "index, follow" : "noindex", vmid: "robots" }];

    return {
        meta: [
            { property: "og:title", content: pageTitle },
            { name: "twitter:title", content: pageTitle },
            ...robots,
        ],
        title: pageTitle
    };
}
