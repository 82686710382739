<template>
    <div class="space--top space--bottom">
        <div class="container-lg">
            <h1>{{ $t('Pages.Imprint.Headline') }}</h1>
            <p>Mystery Minds GmbH</p>
            <!-- eslint-disable vue/no-v-html -->
            <p v-html="$t('Pages.Imprint.Address')" />
            <p v-html="$t('Pages.Imprint.Contact')" />
            <p v-html="$t('Pages.Imprint.ManagementAndTax')" />
            <p v-html="$t('Pages.Imprint.Registration')" />
            <!-- eslint-enable -->
        </div>
    </div>
</template>

<script>
import {pageTitleMetaInfo} from "@/i18n/page-title-meta-info";

export default {
    name: "Imprint",
    metaInfo() {
        return pageTitleMetaInfo(this.$t("Pages.Imprint.PageTitle"));
    }
};
</script>
