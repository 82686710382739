<template>
    <div class="share">
        <div class="container-lg">
            <div class="share__inner">
                <div class="share__text">
                    <h2 class="share__headline">
                        {{ $t('Share.Headline') }}
                    </h2>

                    <p class="share__copy">
                        {{ $t('Share.SubCopy') }}
                    </p>

                    <div class="team-url">
                        <div class="share-url__copy input-group">
                            <input
                                type="text"
                                class="text-input"
                                readonly
                                :value="url"
                                @click="onClick"
                            >
                            <button
                                v-clipboard:copy="url"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                                class="btn btn--primary has--icon"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    width="20"
                                    fill="currentColor"
                                    class="btn__svg"
                                >
                                    <path
                                        d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"
                                    />
                                </svg>
                                <span>{{ $t('Share.Copy') }}</span>
                            </button>

                            <span
                                class="share-url__status"
                                :class="{ 'is--copying': isCopying, 'is--copied': isCopied }"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    width="18"
                                    height="18"
                                >
                                    <path
                                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                    />
                                </svg>
                                {{ $t('Share.Copied') }}
                            </span>
                        </div>

                        <div class="share-url__divider">
                            <span>{{ $t('Share.Or') }}</span>
                        </div>

                        <div class="share-url__send">
                            <a
                                class="share-url__link anchor"
                                :href="mailHref"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    width="20"
                                    fill="currentColor"
                                >
                                    <path
                                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                                    />
                                </svg>
                                <span>{{ $t('Share.Send') }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="share__visual">
                    <img
                        src="/img/content/share-team.svg"
                        width="505"
                        height="467"
                        class="share__svg"
                        alt=""
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Share",
    data: () => {
        return {
            isCopying: false,
            isCopied: false
        };
    },
    computed: {
        url() {
            return "https://hythere.io/#" + this.$route.path;
        },
        mailHref() {
            return "mailto:?subject=" + this.$t("Share.MailSubject") + "&body=HyThere " + this.$root.$data.team.name + " - Team" + this.$t("Share.MailBodyIntro") + this.url + this.$t("Share.MailBodyText");
        }
    },
    methods: {
        onClick() {
            this.$copyText(this.url).then(this.onCopy, this.onError);
        },
        onCopy() {
            this.isCopying = true;

            setTimeout(() => {
                this.isCopying = false;
                this.isCopied = true;

                setTimeout(() => {
                    this.isCopied = false;
                }, 600);

            }, 1600);
        },
        onError() {
            alert(this.$t("Share.Error"));
        }
    }
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

.share {
    background: #fff;
    padding: 3.125rem $page-padding;
}

.share__inner {
    width: 100%;
    display: grid;
    grid-gap: 3.125rem; // 50px
    grid-template-columns: repeat(2, 1fr);
    //grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;

    @media (max-width: $mobile-max) {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
    }
}

.share__text {
}

.share__headline {
    //text-align: center;
}

.share__copy {
    //text-align: center;
    margin: 0 0 $space-md 0;
}

.share__visual {
    @media (max-width: $mobile-max) {
        padding: 0 1.875rem;
    }
}

.share__svg {
    max-width: 25rem;
    width: 100%;
}

.share-url {
    padding: 0 $page-padding;
    max-width: 31.875rem;
    margin: 0 auto;
}

.share-url__divider {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    padding: 2.5rem 0;
    font-size: 1rem;

    span {
        padding: 0 0.9375rem;
    }

    &::before,
    &::after {
        content: "";
        flex: 0 1 50%;
        width: 50%;
        height: 1px;
        background: $border-color;
    }

    display: none;
}

.share-url__copy {
    position: relative;
}

.share-url__status {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    position: absolute;
    background: #a3ebb1;
    font-weight: 700;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

    &.is--copying {
        display: flex;
        animation-name: popIn;
        animation-duration: 0.7s;
    }

    &.is--copied {
        display: flex;
        animation-name: popOut;
        animation-duration: 0.7s;
    }
}

.share-url__error {
    display: none;
}

.share-url__send {
    margin: 1.875rem 0 0 0;
    //text-align: center;
}

.share-url__link {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    color: $text-color;
    font-size: 1rem;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

@keyframes popIn {
    0% {
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    40% {
        -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
        animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
        -webkit-transform: scale3d(1.08, 1.08, 1.08);
        transform: scale3d(1.08, 1.08, 1.08);
    }
    60% {
        -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    80% {
        -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    100% {
        -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes popOut {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    60% {
        -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        -webkit-transform: scale3d(1.08, 1.08, 1.08);
        transform: scale3d(1.08, 1.08, 1.08);
    }
    80% {
        opacity: 1;
        -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
        animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
</style>
