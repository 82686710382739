<template>
    <div>
        <!-- legend -->
        <div class="legend">
            <div class="legend__inner">
                <div class="legend__item">
                    <div class="calendar__button">
                        <span class="calender__availability calender__availability--home">
                            <span
                                aria-hidden="true"
                                class="calendar__availability-icon"
                            />
                        </span>
                    </div>
                    <span class="legend__text">{{ $t('Pages.Team.Legend.Home') }}</span>
                </div>
                <div class="legend__item">
                    <div class="calendar__button">
                        <span class="calender__availability calender__availability--office">
                            <span
                                aria-hidden="true"
                                class="calendar__availability-icon"
                            />
                        </span>
                    </div>
                    <span class="legend__text">{{ $t('Pages.Team.Legend.Office') }}</span>
                </div>
                <div class="legend__item">
                    <div class="calendar__button">
                        <span class="calender__availability calender__availability--vacation">
                            <span
                                aria-hidden="true"
                                class="calendar__availability-icon"
                            />
                        </span>
                    </div>
                    <span class="legend__text">{{ $t('Pages.Team.Legend.Vacation') }}</span>
                </div>
                <div class="legend__item">
                    <div class="calendar__button">
                        <span class="calender__availability calender__availability--not-available">
                            <span
                                aria-hidden="true"
                                class="calendar__availability-icon"
                            />
                        </span>
                    </div>
                    <span class="legend__text">{{ $t('Pages.Team.Legend.NotAvailable') }}</span>
                </div>
            </div>
        </div><!-- ./legend -->

        <div class="calendar">
            <div class="calendar__inner">
                <!-- navigation -->
                <div class="calendar__navigation">
                    <span class="calendar__month">{{ days[startDay].month }}</span>

                    <button
                        class="calendar__arrow"
                        :disabled="startDay === 0"
                        @click="weekLeft()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                            width="30"
                            height="23"
                        >
                            <path
                                d="M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485 116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686 16.97-.001z"
                            />
                        </svg>
                        <span class="visually-hidden">{{ $t('Pages.Team.PreviousWeek') }}</span>
                    </button>

                    <span class="calendar__week">
                        <span class="calendar__week-nr">{{ days[startDay].calendarWeek }}</span>
                        <span class="calendar__week-range">{{ weekDateRange }}</span>
                    </span>

                    <button
                        class="calendar__arrow"
                        :disabled="startDay + 7 >= numberOfDays"
                        @click="weekRight()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                            width="30"
                            height="23"
                        >
                            <path
                                d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"
                            />
                        </svg>
                        <span class="visually-hidden">{{ $t('Pages.Team.NextWeek') }}</span>
                    </button>
                </div><!-- ./navigation -->

                <!-- weekdays -->
                <div class="calendar__weekdays">
                    <div
                        v-for="day in days.slice(startDay, startDay + 5)"
                        :key="day.key"
                        :class="['calendar__weekday', {'is--today': isToday(day)}]"
                    >
                        <span class="calendar__weekday-name">{{ day.name }}</span>
                        <span class="calendar__weekday-nr">{{ day.nr }}</span>
                    </div>
                </div><!-- ./weekdays -->

                <!-- members -->
                <div class="calendar__members">
                    <div
                        v-for="member in members"
                        :key="member.id"
                        class="calendar__row"
                    >
                        <template v-if="isCurrentlyEditing[member.id]">
                            <div class="calendar__member is--editing">
                                <button
                                    class="calendar__member-delete"
                                    @click="removeMember(member.id)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        fill="currentColor"
                                    >
                                        <path
                                            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                                        />
                                    </svg>
                                    <span class="visually-hidden">{{ $t('Commands.Delete') }}</span>
                                </button>

                                <div class="calendar__member-inputs">
                                    <input
                                        v-model="temporaryName[member.id]"
                                        type="text"
                                        class="calendar__member-input"
                                        @keyup.enter="updateMemberData(member)"
                                        @keyup.esc="stopEditingMemberData(member)"
                                    >

                                    <input
                                        v-model="temporaryEmail[member.id]"
                                        type="email"
                                        class="calendar__member-input"
                                        :placeholder="$t('Forms.YourEmail')"
                                        @keyup.enter="updateMemberData(member)"
                                        @keyup.esc="stopEditingMemberData(member)"
                                    >
                                </div>

                                <button
                                    class="calendar__member-save"
                                    :disabled="temporaryName[member.id] === ''"
                                    @click="updateMemberData(member)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        fill="currentColor"
                                    >
                                        <path
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        />
                                    </svg>
                                    <span class="visually-hidden">{{ $t('Commands.Save') }}</span>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calendar__member">
                                <span class="calendar__member-name">{{ member.name }}</span>
                                <button
                                    class="calendar__member-edit"
                                    @click="startEditingMemberData(member)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        fill="currentColor"
                                    >
                                        <path
                                            d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                                        />
                                    </svg>
                                    <span class="visually-hidden">{{ $t('Commands.Edit') }}</span>
                                </button>
                            </div>
                        </template>

                        <button
                            v-for="day in days.slice(startDay, startDay + 5)"
                            :key="day.key"
                            :aria-label="getWholeDayAvailabilityLabel(member, day)"
                            class="calendar__button"
                            @click="onDayClick(member, day)"
                        >
                            <span
                                v-for="(availability, index) in (member.availabilities[day.key] || ['empty'])"
                                :key="day.key + '-' + index"
                                :class="'calender__availability calender__availability--' + availability"
                                aria-hidden="true"
                            >
                                <span
                                    class="calendar__availability-icon"
                                    aria-hidden="true"
                                />
                            </span>
                        </button>

                        <div
                            v-if="showingAvailabilityDropdownForMember !== null && showingAvailabilityDropdownForMember.id === member.id"
                            v-click-outside="onAvailabilityDropdownOutsideClick"
                            class="status"
                        >
                            <div class="status__inner">
                                <div
                                    class="status__row"
                                >
                                    <div class="status__item">
                                        <button
                                            class="calendar__button"
                                            @click="onWholeDayAvailabilityClick('home')"
                                        >
                                            <span
                                                class="calender__availability calender__availability--home"
                                                aria-hidden="true"
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    class="calendar__availability-icon"
                                                />
                                            </span>
                                        </button>
                                        <span class="status__text">{{ $t('Pages.Team.Legend.Home') }}</span>
                                    </div>

                                    <div class="status__item">
                                        <button
                                            class="calendar__button"
                                            @click="onWholeDayAvailabilityClick('office')"
                                        >
                                            <span class="calender__availability calender__availability--office">
                                                <span
                                                    aria-hidden="true"
                                                    class="calendar__availability-icon"
                                                />
                                            </span>
                                        </button>
                                        <span class="status__text">{{ $t('Pages.Team.Legend.Office') }}</span>
                                    </div>

                                    <div class="status__item">
                                        <button
                                            class="calendar__button"
                                            @click="onWholeDayAvailabilityClick('vacation')"
                                        >
                                            <span class="calender__availability calender__availability--vacation">
                                                <span
                                                    aria-hidden="true"
                                                    class="calendar__availability-icon"
                                                />
                                            </span>
                                        </button>
                                        <span class="status__text">{{ $t('Pages.Team.Legend.Vacation') }}</span>
                                    </div>

                                    <div class="status__item">
                                        <button
                                            class="calendar__button"
                                            @click="onWholeDayAvailabilityClick('not-available')"
                                        >
                                            <span class="calender__availability calender__availability--not-available">
                                                <span
                                                    aria-hidden="true"
                                                    class="calendar__availability-icon"
                                                />
                                            </span>
                                        </button>
                                        <span class="status__text">{{ $t('Pages.Team.Legend.NotAvailable') }}</span>
                                    </div>
                                </div>

                                <div
                                    v-if="showHalfdays"
                                    class="halfdays"
                                >
                                    <div class="halfdays__inner">
                                        <!-- forenoon -->
                                        <div class="halfdays__item">
                                            <div class="halfdays__header">
                                                <svg
                                                    class="halfdays__icon"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"
                                                    />
                                                </svg>
                                                <span>{{ $t('Words.Beforenoon') }}</span>
                                            </div>

                                            <div class="halfdays__buttons">
                                                <div>
                                                    <input
                                                        id="forenoonHome"
                                                        v-model="forenoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="forenoon"
                                                        value="home"
                                                    >

                                                    <label
                                                        for="forenoonHome"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--home"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        id="forenoonOffice"
                                                        v-model="forenoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="forenoon"
                                                        value="office"
                                                    >

                                                    <label
                                                        for="forenoonOffice"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--office"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        id="forenoonVacation"
                                                        v-model="forenoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="forenoon"
                                                        value="vacation"
                                                    >

                                                    <label
                                                        for="forenoonVacation"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--vacation"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        id="forenoonNotAvailable"
                                                        v-model="forenoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="forenoon"
                                                        value="not-available"
                                                    >

                                                    <label
                                                        for="forenoonNotAvailable"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--not-available"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div><!-- ./forenoon -->

                                        <!-- afternoon -->
                                        <div class="halfdays__item">
                                            <div class="halfdays__header">
                                                <svg
                                                    class="halfdays__icon"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    width="20"
                                                    height="20"
                                                >
                                                    <path
                                                        d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z"
                                                    />
                                                </svg>
                                                <span>{{ $t('Words.Afternoon') }}</span>
                                            </div>

                                            <div class="halfdays__buttons">
                                                <div>
                                                    <input
                                                        id="afternoonHome"
                                                        v-model="afternoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="afternoon"
                                                        value="home"
                                                    >

                                                    <label
                                                        for="afternoonHome"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--home"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        id="afternoonOffice"
                                                        v-model="afternoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="afternoon"
                                                        value="office"
                                                    >

                                                    <label
                                                        for="afternoonOffice"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--office"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        id="afternoonVacation"
                                                        v-model="afternoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="afternoon"
                                                        value="vacation"
                                                    >

                                                    <label
                                                        for="afternoonVacation"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--vacation"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        id="afternoonNotAvailable"
                                                        v-model="afternoon"
                                                        type="radio"
                                                        class="halfdays__radio"
                                                        name="afternoon"
                                                        value="not-available"
                                                    >

                                                    <label
                                                        for="afternoonNotAvailable"
                                                        class="calendar__button"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            class="calender__availability calender__availability--not-available"
                                                        >
                                                            <span class="calendar__availability-icon" />
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div><!-- ./afternoon -->
                                    </div>

                                    <!-- submit -->
                                    <div class="halfdays__submit">
                                        <button
                                            class="btn btn--primary"
                                            :disabled="!(forenoon || afternoon)"
                                            @click="onHalfdaySelectClick"
                                        >
                                            {{ $t('Commands.Select') }}
                                        </button>
                                    </div><!-- ./submit -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- ./members -->

                <!-- sum -->
                <div class="calendar__sum">
                    <div class="calendar__sum-inner">
                        <div class="calendar__sum-grid">
                            <span
                                v-for="day in days.slice(startDay, startDay + 5)"
                                :key="day.key"
                                class="calendar__sum-day"
                            >
                                {{ numberOfMembersInOffice(day) }}
                            </span>
                        </div>

                        <div class="calendar__sum-info">
                            <span>{{ $t('Pages.Team.SumInfo') }}</span>
                        </div>
                    </div>
                </div>
                <!-- ./sum -->

                <!-- halfdays switch -->
                <div class="calendar__switch">
                    <div class="switch">
                        <input
                            id="halfdaysSwitch"
                            v-model="showHalfdays"
                            type="checkbox"
                            class="switch__checkbox"
                        >
                        <label
                            class="switch__label"
                            for="halfdaysSwitch"
                        >
                            <span class="switch__text">{{ $t('Pages.Team.ShowHalfdays') }}</span>
                        </label>
                    </div>
                </div><!-- ./halfdays switch -->
            </div>

            <!-- add member -->
            <div class="add-member">
                <h2 class="add-member__headline">
                    {{ $t('Pages.Team.AddMember') }}
                </h2>

                <div class="add-member__group">
                    <label
                        for="tbAddMember"
                        class="add-member__label"
                    >
                        <svg
                            class="add-member__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                        >
                            <path
                                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                            />
                        </svg>
                        <span class="visually-hidden">{{ $t('Concepts.Name') }}</span>
                    </label>
                    <input
                        id="tbAddMember"
                        ref="newName"
                        v-model="newName"
                        type="text"
                        class="add-member__input"
                        required
                        :placeholder="$t('Concepts.Name')"
                        @keyup.enter="addMember"
                        @keyup.esc="cancelAddingMember"
                    >
                </div>

                <div class="add-member__group">
                    <label
                        for="tbAddMemberEmail"
                        class="add-member__label"
                    >
                        <svg
                            class="add-member__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                        >
                            <path
                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                            />
                        </svg>
                        <span class="visually-hidden">{{ $t('Concepts.OptionalEmail') }}</span>
                    </label>
                    <input
                        id="tbAddMemberEmail"
                        ref="newEmail"
                        v-model="newEmail"
                        type="email"
                        class="add-member__input"
                        :placeholder="$t('Concepts.OptionalEmail')"
                        @keyup.enter="addMember"
                        @keyup.esc="cancelAddingMember"
                    >
                </div>

                <div class="add-member__submit">
                    <button
                        class="btn btn--primary has--icon full--width"
                        type="button"
                        @click="addMember"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="20"
                            fill="currentColor"
                        >
                            <path
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                            />
                        </svg>
                        <span>{{ $t('Commands.AddMember') }}</span>
                    </button>
                </div>
            </div>
            <!-- ./add member -->
        </div>

        <Share />
    </div>
</template>

<script>
import {DateTime} from "luxon";
import Share from "@/components/Share";

export default {
    name: "TeamMembers",
    components: {Share},
    props: {
        members: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        const NUMBER_OF_DAYS = 7 * 20;

        const days = [];
        const startOfWeek = DateTime.now().startOf("week");

        for (let i = 0; i < NUMBER_OF_DAYS; i++) {
            const day = startOfWeek.plus({days: i});

            days.push({
                calendarWeek: this.$t("DateTime.CalendarWeek") + day.toFormat("WW"),
                key: day.toFormat("yyyy-MM-dd"),
                month: this.$t("DateTime.Month_" + day.toFormat("LL")),
                monthNr: day.toFormat("LL"),
                name: this.$t("DateTime.Day_" + day.toFormat("c")),
                nr: day.toFormat("dd")
            });
        }

        return {
            days,
            forenoon: null,
            afternoon: null,
            startDay: 0,
            newEmail: "",
            newName: "",
            numberOfDays: NUMBER_OF_DAYS,
            isCurrentlyEditing: {},
            showingAvailabilityDropdownForMember: null,
            showingAvailabilityDropdownForDay: null,
            temporaryEmail: {},
            temporaryName: {},
            showHalfdays: false
        };
    },
    computed: {
        isShowingAvailabilityDropdown() {
            return this.showingAvailabilityDropdownForMember !== null && this.showingAvailabilityDropdownForDay !== null;
        },
        weekDateRange() {
            let separator = this.$i18n.locale === "en" ? "/" : ".";

            return this.days[this.startDay].nr + separator + this.days[this.startDay].monthNr + " - " +
                   this.days[this.startDay + 4].nr + separator + this.days[this.startDay + 4].monthNr;
        }
    },
    watch: {
        showHalfdays(showHalfdays) {
            localStorage.showHalfdays = JSON.stringify(showHalfdays);
        }
    },
    mounted() {
        if (localStorage.showHalfdays) {
            this.showHalfdays = JSON.parse(localStorage.showHalfdays);
        }
    },
    methods: {
        addMember: function () {
            if (this.newName === "") {
                this.$refs.newName.focus();
                return;
            }

            this.$emit("added-member", {
                name: this.newName,
                email: this.newEmail,
                locale: this.$i18n.locale
            });

            this.newName = "";
            this.newEmail = "";
        },
        cancelAddingMember: function () {
            this.newName = "";
            this.newEmail = "";
        },
        isToday: function (day) {
            return day.key === DateTime.now().toFormat("yyyy-MM-dd");
        },
        startEditingMemberData: function (member) {
            this.$set(this.temporaryName, member.id, member.name);
            this.$set(this.temporaryEmail, member.id, member.email);
            this.$set(this.isCurrentlyEditing, member.id, true);
        },
        stopEditingMemberData: function (member) {
            this.$delete(this.isCurrentlyEditing, member.id);
        },
        updateMemberData: function (member) {
            const name = this.temporaryName[member.id];
            const email = this.temporaryEmail[member.id];

            if (name === "") {
                return;
            }

            this.$delete(this.isCurrentlyEditing, member.id);
            this.$delete(this.temporaryName, member.id);
            this.$delete(this.temporaryEmail, member.id);

            this.$emit("updated-member-data", {member, name, email});
        },
        removeMember: function (id) {
            this.$emit("removed-member", {id});
        },
        getWholeDayAvailabilityLabel(member, day) {
            const availabilities = member.availabilities[day.key];

            if (!availabilities) {
                return "empty";
            }

            return availabilities.join(" / ");
        },
        numberOfMembersInOffice(day) {
            let sum = 0;

            for (const member of this.members) {
                if (member.availabilities[day.key]) {
                    for (const availability of member.availabilities[day.key]) {
                        if (availability === "office") {
                            sum++;
                            break;
                        }
                    }
                }
            }

            return sum;
        },
        onDayClick(member, day) {
            if (
                this.isShowingAvailabilityDropdown &&
                this.showingAvailabilityDropdownForMember.id === member.id &&
                this.showingAvailabilityDropdownForDay.key === day.key
            ) {
                // Clicked again on the already opened member day
                this.closeAvailabilityDropdown();
                return;
            }

            // Already set?
            if (member.availabilities[day.key]) {
                if (member.availabilities[day.key].length === 1) {
                    // Whole day selected
                    this.forenoon = member.availabilities[day.key][0];
                    this.afternoon = member.availabilities[day.key][0];
                } else {
                    // Forenoon/afternoon selected separately
                    this.forenoon = member.availabilities[day.key][0];
                    this.afternoon = member.availabilities[day.key][1];
                }
            }

            this.showingAvailabilityDropdownForMember = member;
            this.showingAvailabilityDropdownForDay = day;
        },
        onWholeDayAvailabilityClick(availability) {
            this.$emit("updated-member-availability", {
                member: this.showingAvailabilityDropdownForMember,
                dayKey: this.showingAvailabilityDropdownForDay.key,
                availability: [availability]
            });

            this.closeAvailabilityDropdown();
        },
        onHalfdaySelectClick() {
            const availability =
                this.forenoon === this.afternoon ?
                    [this.forenoon] :
                    [
                        this.forenoon || "empty",
                        this.afternoon || "empty",
                    ];

            this.$emit("updated-member-availability", {
                member: this.showingAvailabilityDropdownForMember,
                dayKey: this.showingAvailabilityDropdownForDay.key,
                availability
            });

            this.closeAvailabilityDropdown();
        },
        onAvailabilityDropdownOutsideClick() {
            this.closeAvailabilityDropdown();
        },
        weekLeft() {
            if (this.startDay === 0) {
                return;
            }

            this.startDay -= 7;
        },
        weekRight() {
            if (this.startDay + 7 >= this.numberOfDays) {
                return;
            }

            this.startDay += 7;
        },
        closeAvailabilityDropdown() {
            this.showingAvailabilityDropdownForMember = null;
            this.showingAvailabilityDropdownForDay = null;
            this.forenoon = null;
            this.afternoon = null;
        }
    }
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

// legend
// --------------------------------------------------------
.legend {
    padding: 0 $page-padding;
}

.legend__inner {
    width: 100%;
    max-width: 31.875rem;
    margin: 0 auto 1.25rem auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 0.625rem 0.3125rem;
    background: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.05);
}

.legend__item {
    flex: 1 1 0;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
}

.legend__text {
    padding: 0.5rem 0 0 0;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
}

// <div> wrapper
// --------------------------------------------------------
.calendar {
    padding: 0 $page-padding;
    margin: 0 0 $space-xl 0;
}

// inner wrapper
// --------------------------------------------------------
.calendar__inner {
    background: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.2);
    max-width: 31.875rem;
    width: 100%;
    margin: 0 auto;
}

// <div> navigation
// --------------------------------------------------------
.calendar__navigation {
    //grid-column: span 5;
    border-radius: 0.625rem 0.625rem 0 0;
    background: linear-gradient(to right, #745aef 0%, #52bcf3 100%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.9375rem 1.875rem;
}

// <span> month
.calendar__month {
    font-size: 1.5rem; // 1.5rem
    font-weight: 700;
    margin-right: auto; // moves the other items to the right side
}

// <button> arrow
.calendar__arrow {
    flex: 0 0 1.875rem;
    width: 1.875rem;
    max-width: 1.875rem;
    padding: 0 0.3125rem;
    display: inline-flex;

    svg {
        width: 30px;
        height: auto;
    }

    &[disabled] {
        opacity: 0;
        pointer-events: none;
    }
}

// <span> calendar week
.calendar__week {
    font-size: 1rem; // 1rem
    padding: 0 0.9375rem;
    text-align: center;
}

.calendar__week-nr {
    display: block;
}

.calendar__week-range {
    font-size: 12px;
    margin: 5px 0 0 0;
    display: block;
    white-space: nowrap;

    @media (max-width: 23.375rem) {
        display: none;
    }

    &:empty {
        display: none;
    }
}

// <div> weekdays
// --------------------------------------------------------
.calendar__weekdays {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0.625rem;
}

// <div> item
.calendar__weekday {
    text-align: center;
    line-height: 1.25;
    width: 3.125rem;
    height: 3.125rem;
    justify-self: center;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    &.is--today {
        border: 1px solid #52bcf3;
        background: #f1fafe;
        font-size: 0.875rem;
    }
}

// <span> name of day
.calendar__weekday-name {
    font-size: inherit;
    font-weight: 700;
    display: block;
}

// <span> day of month
.calendar__weekday-nr {
    font-size: 0.875rem; //0.875rem
    //margin: 0.3125rem 0 0 0;
    display: block;
}


// <div> calendar members
// --------------------------------------------------------
.calendar__members {
    padding: 0 0.625rem 0.625rem 0.625rem;
}

// <div> row grid
.calendar__row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    padding: 0.9375rem 0;

    @media(max-width: $mobile-max) {
        background: #f9f9f9;
        box-shadow: inset 0 -0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.05);
        border-radius: 0.625rem;
        margin: 0 0 0.625rem 0;
        min-height: 135px;
    }
}

// <div> member name
.calendar__member {
    grid-column: span 5;
    border-radius: 0.3125rem 0 0 0.3125rem;
    margin: 0 0 0.9375rem 0;
    padding: 0 0.9375rem;
    font-size: 0.875rem;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $mobile-max) {
        &.is--editing {
            //margin: 0 0 20px 0;
            //min-height: 2.5rem;
            border-radius: 0.3125rem;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: #5b94f3;
            color: #fff;
            gap: 10px;
        }
    }

    @media(min-width: $desktop-min) {
        height: 2.5rem;

        &.is--editing {
            height: 4.6875rem;
        }

        gap: 5px;
        transform: translate(-100%, -50%);
        margin-bottom: 0;
        background: #5b94f3;
        top: 50%;
        position: absolute;
        font-size: 1.125rem;
        width: 15rem;
        color: #fff;
        padding: 0;

        // add triangle
        &::after {
            content: "";
            background: #5b94f3;
            -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
            height: 100%;
            position: absolute;
            right: -0.75rem;
            width: 0.75rem;
        }
    }
}

// <div> name
.calendar__member-name {
    line-height: 2.5rem;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    font-weight: 700;

    @media (min-width: $desktop-min) {
        margin: 0 0 0 0.9375rem;
    }
}

// <div> inputs
.calendar__member-inputs {
    flex: 1;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    @media (min-width: $desktop-min) {
        gap: 5px;
    }
}

// <input>
.calendar__member-input {
    border: 0;
    outline: 0;
    padding: 0 0.625rem;
    align-self: center;
    border-radius: 0.3125rem;
    color: $text-color;
    font-size: 1rem;
    height: 1.5625rem;
    width: 100%;

}

// <button> delete
.calendar__member-delete {
    flex: 0 0 2.1875rem;
    width: 2.1875rem;
    height: auto;
    padding: 0 0.625rem;
}

// <button> edit
.calendar__member-edit {
    margin-left: auto;
    width: 2rem;
    padding: 0 0.625rem;
}

// <button> save
.calendar__member-save {
    flex: 0 0 2.1875rem;
    width: 2.1875rem;
    margin-left: auto;
    padding: 0 0.625rem;
}

// <button>
.calendar__button {
    border-radius: 50%;
    padding: 0;
    overflow: hidden;
    justify-self: center;
    display: inline-flex;
    align-items: stretch;
    gap: 1px;
    transition: 0.1s cubic-bezier(0.52, 0.16, 0.24, 1);

    transform: rotate(35deg);

    @media (max-width: 25rem) {
        width: 2.8125rem;
        height: 2.8125rem;
    }

    @media (min-width: 25.0625rem) {
        width: 3.125rem;
        height: 3.125rem;
    }

    // make it smaller within the legend
    .status__item &,
    .legend__item & {
        width: 40px;
        height: 40px;
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.25);
    }

    &:hover {
        //transform: scale(1.04);
    }

    &.calendar__button--empty {
        // make sure the pulse animation is visible on 'empty' days
        overflow: visible;
    }
}

// <button> availability
.calender__availability {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    // if its halfdays, change the appearance
    &:not(:only-child) {
        flex: 0 0 50%;

        &:first-child {
            .calendar__availability-icon {
                transform: rotate(-35deg) translateX(1px);
            }
        }

        &:last-child {
            .calendar__availability-icon {
                transform: rotate(-35deg) translateX(-1px);
            }
        }

        .calendar__availability-icon {
            width: 14px;
            height: 14px;
        }
    }
}

// <modifiers>
.calender__availability--home {
    background: linear-gradient(45deg, #a45aef 0%, #f35298 100%);
}

.calender__availability--office {
    background: linear-gradient(45deg, #76ef5a 0%, #52cdf3 100%);
}

.calender__availability--vacation {
    background: linear-gradient(45deg, #ef985a 0%, #f3ec52 100%);
}

.calender__availability--not-available {
    background: linear-gradient(45deg, #FB4570 0%, #f57b75 100%);
}

.calender__availability--empty {
    // halfdays
    &:not(:only-child) {
        background-color: $border-color;

        .calendar__availability-icon {
            background-image: url('/public/img/icons/question-white.svg');
        }
    }

    // fulldays
    &:only-child {
        border: 0.0625rem solid $border-color;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 rgba(208, 220, 251, 0.5);
        animation: pulse 2.5s infinite;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(208, 220, 251, 0.5);
    }
    70% {
        box-shadow: 0 0 0 12px rgba(208, 220, 251, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(208, 220, 251, 0);
    }
}


// <span> icon
.calendar__availability-icon {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    transform: rotate(-35deg);

    // make it smaller within the legend
    .halfdays__buttons &,
    .status__item &,
    .legend__item & {
        width: 16px;
        height: 16px;
    }

    .calender__availability--home & {
        background-image: url('/public/img/icons/house-white.svg');
    }

    .calender__availability--office & {
        background-image: url('/public/img/icons/building-white.svg');
    }

    .calender__availability--vacation & {
        background-image: url('/public/img/icons/island-tropical-white.svg');
    }

    .calender__availability--not-available & {
        background-image: url('/public/img/icons/ban-white.svg');
    }

    .calender__availability--empty & {
        background-image: url('/public/img/icons/question-violet.svg');
    }
}


// status
// --------------------------------------------------------
.status {
    grid-column: span 5;
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    transform: translateY(-0.3125rem);
    z-index: 1;
    padding: 0 10px;

    @media (min-width: $desktop-min) {
        padding: 0 20px;
    }
}

.status__inner {
    padding: 15px;
    border-radius: 0.625rem;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #fff;
    background: #fff;

    &::before {
        content: "";
        background: #fff;
        -webkit-clip-path: polygon(50% 0%, 0 50%, 100% 50%);
        clip-path: polygon(50% 0%, 0 50%, 100% 50%);
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 20px;
        height: 10px;
    }
}

.status__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.status__item {
    flex: 1 1 0;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
}

.status__text {
    padding: 0.3125rem 0 0 0;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
}

// <div> switch
// --------------------------------------------------------
.calendar__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $mobile-max) {
        padding: 1.25rem 0.625rem 1.25rem 0.625rem;
    }

    @media (min-width: $desktop-min) {
        padding: 1.25rem 2.5rem 1.25rem 2.5rem;
    }
}


// <div> add members
// --------------------------------------------------------
.add-member {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 31.875rem;
    width: 100%;
    margin: 2rem auto 0 auto;
    background: #fff;
    padding: 30px;
    border-radius: 0.625rem;
    box-shadow: 0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.05);
}

.add-member__inner {
    width: 100%;
    //max-width: 320px;
}

.add-member__icon {
    width: 30px;
    height: auto;
}

.add-member__headline {
    font-size: 1.5rem;
    margin: 0 0 30px 0;
}


.add-member__group {
    flex: 1;
    display: flex;
    align-items: stretch;
    gap: 0.625rem;
    margin: 0 0 10px 0;

    border-bottom: 0.0625rem solid #d0dcfb;
    //border-radius: 25px;
}

.add-member__label {
    display: inline-flex;
    padding: 0 0 0 0.9375rem;
}

.add-member__icon {
    width: 15px;
    height: auto;
}

.add-member__input {
    border: 0;
    //border-bottom: 0.0625rem solid #d0dcfb;
    background: transparent;
    height: $input-height;
    //padding: 0 0.625rem;
    flex: 1;
}

.add-member__submit {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

// calendar sum
// --------------------------------------------------------
.calendar__sum {
    position: relative;
    padding: 0 0.625rem;
}

.calendar__sum-inner {
    padding: 0.9375rem 0;
    background: #f9f9f9;
    border-radius: 0.625rem;
    box-shadow: inset 0 -0.0625rem 0.1875rem 0 rgb(0 0 0 / 5%);
}

.calendar__sum-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
}

.calendar__sum-day {
    width: 3.125rem;
    //height: 3.125rem;
    border-radius: 50%;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.calendar__sum-info {
    display: block;
    padding: 1.25rem 2.5rem 0 2.5rem;
    text-align: center;
    font-size: 14px;
}

// <div> halfdays
// --------------------------------------------------------
.halfdays {
    padding: 30px 0 10px 0;
    //margin: 30px 0 0 0;
    //border-top: 1px solid $border-color;
}

.halfdays__inner {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    gap: 15px;

    @media (max-width: 23.375rem) {
        flex-direction: column;
    }
}

.halfdays__item {
    background: #f5f5f5;
    flex: 1 1 0;
    border-radius: 25px;
    padding: 15px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.halfdays__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.625rem;
    font-size: 0.875rem;
}

.halfdays__icon {

}

.halfdays__radio {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;

    &:not(:checked) ~ .calendar__button .calender__availability {
        background: #999;
    }
}

.halfdays__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.9375rem;

    .calendar__button {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}

.halfdays__submit {
    margin: 30px 0 0 0;
    text-align: center;
}
</style>
