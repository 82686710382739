<template>
    <div class="language">
        <span
            v-for="lang in langs"
            :key="lang"
            :class="['locale__link', { 'is--active': lang === $i18n.locale}]"
            @click="setLocale(lang)"
        >
            {{ lang }}
        </span>
    </div>
</template>

<script>
import locales from "@/config/supported-locales";
import {navigateToLocale} from "@/i18n/navigate-to-locale";

export default {
    name: "LocaleSwitcher",
    data() {
        return {
            langs: locales
        };
    },
    methods: {
        setLocale(locale) {
            navigateToLocale(locale);
        }
    }
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

.locale__link {
    text-transform: uppercase;
    color: $link-color;
    padding: 0 0.9375rem;
    font-size: 1.125rem;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: 21.875rem) {
        padding: 0 0.625rem;
    }

    &:first-child {
        border-right: 0.125rem solid $link-color;
    }

    &:hover,
    &:focus,
    &.is--active {
        font-weight: 700;
    }
}
</style>
