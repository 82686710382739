<template>
    <footer class="footer">
        <div class="footer__inner">
            <div :class="['footer__copyright', $root.$data.isConnected ? 'is--connected' : 'is--disconnected' ]">
                <span>{{ $t('Copyright.Before') }}</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="28"
                    fill="currentColor"
                >
                    <path
                        d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                    />
                </svg>
                <span>{{ $t('Copyright.After') }}</span>
            </div>

            <!-- links -->
            <nav class="footer__links">
                <ul class="footer__list">
                    <li class="footer__litem">
                        <router-link
                            :to="{ name: 'Imprint', params: { locale: $i18n.locale }}"
                            class="footer__link"
                        >
                            {{
                                $t('Pages.Imprint.Headline') }}
                        </router-link>
                    </li>
                    <li class="footer__litem">
                        <router-link
                            :to="{ name: 'DataProtection', params: { locale: $i18n.locale }}"
                            class="footer__link"
                        >
                            {{ $t('Pages.DataProtection.Headline') }}
                        </router-link>
                    </li>
                </ul>
            </nav>
            <!-- ./links -->
        </div>
    </footer>
</template>

<script>
export default {
    name: "PageFooter"
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

// <div> footer
// --------------------------------------------------------
.footer {
    width: 100%;
    color: $link-color;
    font-size: 1rem;
    padding: 2.8125rem 0 2.8125rem 0;
}

.footer__inner {
    margin: 0 auto;
    max-width: $container-lg;
    padding: 0 $page-padding;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

// copyright
// --------------------------------------------------------
.footer__copyright {
    margin: 0 1.25rem 0.9375rem 0;
    display: inline-flex;
    align-items: center;

    svg {
        width: 1.75rem;
        padding: 0 0.375rem;
    }

    &.is--connected {
        svg {
            color: #f35298;
        }
    }

    &.is--disconnected {
        svg {
            color: #92aef5;
        }
    }
}

// <nav> links
// --------------------------------------------------------
.footer__links {

}

// <ul>
.footer__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

// <li>
.footer__litem {
    display: inline-block;
    padding: 0 0.9375rem;
    margin: 0 0 0.9375rem 0;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    // all  but the first element
    &:not(:first-child) {
        border-left: 0.0625rem solid $link-color;
    }
}

// <a>
.footer__link {
    color: inherit;
    text-decoration: none;

    // todo: link hover transition
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
</style>
