<template>
    <main class="main">
        <router-view />
    </main>
</template>

<script>
export default {
    name: "PageMain"
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

// <main>
// --------------------------------------------------------
.main {
    flex: 1; // stretch it all the way down
}
</style>
