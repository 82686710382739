import App from "@/App.vue";
import vClickOutside from "v-click-outside";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import VueMatomo from "vue-matomo";
import VueMeta from "vue-meta";
import i18n from "@/i18n";
import matomoOptions from "@/config/matomo-options";
import router from "@/router/router";
import store from "@/store";

Vue.config.productionTip = false;

Vue.use(vClickOutside);
Vue.use(VueClipboard);
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

if (process.env.NODE_ENV === "production") {
    Vue.use(VueMatomo, matomoOptions);
}

new Vue({
    data: store,
    router,
    i18n,
    render: h => h(App)
}).$mount("#app");
