import i18n from "@/i18n";
import {setDocumentLang} from "@/i18n/set-document-lang";
import {isSupportedLocale} from "@/i18n/is-supported-locale";

export function setLocale(route) {
    // console.debug("SetLocale: Setting locale " + route.params.locale);

    if (!isSupportedLocale(route.params.locale)) {
        // console.debug("SetLocale: Locale not supported");
        return false;
    }

    i18n.locale = route.params.locale;
    setDocumentLang(route.params.locale);

    return true;
}
