<template>
    <header class="header">
        <div class="header__inner">
            <router-link
                :to="{ name: 'Home', params: { locale: $i18n.locale }}"
                class="logo"
            >
                <img
                    class="logo__img"
                    src="/img/logo.svg"
                    width="300"
                    height="81"
                    :alt="$t('Logo.Alt')"
                >
            </router-link>

            <LocaleSwitcher />
        </div>
    </header>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
    name: "PageHeader",
    components: {LocaleSwitcher}
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

// <div> header
// --------------------------------------------------------
.header {
    width: 100%;
    background: #fff;
    box-shadow: 0 0.125rem 0.3125rem 0 rgba(0,0,0, 0.2);
}

.header__inner {
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $page-padding;
    padding-right: $page-padding;
    max-width: $container-lg;
    margin: 0 auto;
}

// <a> logo
// --------------------------------------------------------
.logo {
    position: relative;

    // beta label
    &::after {
        content: 'Beta';
        padding: 2px 7px;
        color: #fff;
        background: #f1c455;
        border-radius: 20px;
        position: absolute;
        top: 0;
        right: -40px;
        font-weight: bold;
        font-size: 12px;
    }
}

// <img>
.logo__img {
    width: 10.625rem; // 10.625rem
    height: auto;
}
</style>
