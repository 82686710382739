<template>
    <div class="space--top">
        <template v-if="!$root.$data.isConnected">
            <div class="section-intro">
                <h1 class="section__headline">
                    {{ $t('Network.Connecting') }}
                </h1>
            </div>
            <div
                class="loader"
                aria-hidden="true"
            >
                <div class="loader__animation" />
            </div>
        </template>
        <template v-else-if="$root.$data.team === null">
            <div class="section-intro">
                <h1 class="section__headline">
                    {{ $t('Network.LoadingTeam') }}
                </h1>
            </div>
            <div
                class="loader"
                aria-hidden="true"
            >
                <div class="loader__animation" />
            </div>
        </template>
        <template v-else>
            <div class="section-intro">
                <h1 class="section__headline">
                    HyThere {{ $root.$data.team.name }}
                </h1>
            </div>
            <TeamMembers
                :members="$root.$data.team.members"
                @added-member="onAddedMember"
                @removed-member="onRemovedMember"
                @updated-member-availability="onUpdatedMemberAvailability"
                @updated-member-data="onUpdatedMemberData"
            />
        </template>
    </div>
</template>

<script>
import TeamMembers from "@/components/TeamMembers";
import {pageTitleMetaInfo} from "@/i18n/page-title-meta-info";

export default {
    name: "Team",
    components: {
        TeamMembers
    },
    metaInfo() {
        const pageTitle =
            this.$root.$data.team === null ?
                this.$t("Pages.Team.PageTitle") :
                this.$root.$data.team.name;

        // console.debug(pageTitleMetaInfo(pageTitle, false));
        return pageTitleMetaInfo(pageTitle, false);
    },
    beforeRouteEnter(to, from, next) {
        // If we arrived from the Create page, we already received the team data
        if (from.name === "Create") {
            next();

            return;
        }

        next(teamComponent => {
            teamComponent.$root.$data.loadTeam(teamComponent.$route.params.path);
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.$root.$data.leaveTeam();
        this.$root.$data.loadTeam(this.$route.params.path);
        next();
    },
    beforeRouteLeave(to, from, next) {
        this.$root.$data.leaveTeam();
        next();
    },
    methods: {
        onAddedMember(event) {
            this.$root.$data.addMember(event.name, event.email, event.locale);
        },
        onUpdatedMemberData(event) {
            this.$root.$data.updateMemberData(event.member, event.name, event.email);
        },
        onUpdatedMemberAvailability(event) {
            this.$root.$data.updateMemberAvailability(event.member, event.dayKey, event.availability);
        },
        onRemovedMember(event) {
            this.$root.$data.removeMember(event.id);
        }
    }
};
</script>
