import Vue from "vue";
import VueI18n from "vue-i18n";
import {getStartingLocale} from "@/i18n/get-starting-locale";
import {loadLocaleMessages} from "@/i18n/load-locale-messages";

Vue.use(VueI18n);

export default new VueI18n({
    locale: getStartingLocale(),
    fallbackLocale: "en",
    messages: loadLocaleMessages()
});
