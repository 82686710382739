<template>
    <div class="feedback">
        <a
            class="feedback__link"
            href="mailto:feedback@hythere.io?subject=Feedback"
        >
            <svg
                class="feedback__icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="50"
                height="50"
                fill="currentColor"
            >
                <path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zM320 133.2c14.8 0 26.8 12 26.8 26.8s-12 26.8-26.8 26.8-26.8-12-26.8-26.8 12-26.8 26.8-26.8zm-128 0c14.8 0 26.8 12 26.8 26.8s-12 26.8-26.8 26.8-26.8-12-26.8-26.8 12-26.8 26.8-26.8zm164.2 140.9C331.3 303.3 294.8 320 256 320c-38.8 0-75.3-16.7-100.2-45.9-5.8-6.7-5-16.8 1.8-22.5 6.7-5.7 16.8-5 22.5 1.8 18.8 22 46.5 34.6 75.8 34.6 29.4 0 57-12.6 75.8-34.7 5.8-6.7 15.9-7.5 22.6-1.8 6.8 5.8 7.6 15.9 1.9 22.6z" />
            </svg>
            <span>{{ $t('Feedback') }}</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "Feedback"
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global/config/variables';

// <div> wrapper
// --------------------------------------------------------
.feedback {
    width: 100%;
    background: #92aef5;
    padding: 15px 0;
    text-align: center;

    @media (min-width: $desktop-min) {
        text-align: left;
        position: fixed;
        bottom: 3.75rem;
        transform: none;
        left: auto;
        right: 1.25rem;
        width: 8.125rem;
        padding: 0;
    }
}

// <svg> icon
.feedback__icon {
    color: #fff;
    margin: 0 0 20px 0;
    filter: drop-shadow(6px 5px 10px rgba(0, 0, 0, 0.15));

    @media (min-width: $desktop-min) {
        display: none;
    }
}

// <a> link
.feedback__link {
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 0.625rem;
    line-height: 1.3;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $desktop-min) {
        background: #0a266d;
        font-size: 0.875rem;
        border-radius: 0.3125rem 0.3125rem 0 0.3125rem;
        text-align: left;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -0.9375rem;
            border-width: 0.9375rem 0 0 0.9375rem;
            border-style: solid;
            border-color: #0a266d transparent;
            display: block;
        }
    }
}
</style>
