<template>
    <div class="space--top space--bottom">
        <div class="container-lg">
            <div class="landingpage">
                <div class="landingpage__text">
                    <h1 class="landingpage__headline">
                        {{ $t('Pages.Home.Headline') }}
                    </h1>
                    <p class="landingpage__copy">
                        {{ $t('Pages.Home.Copy') }}
                    </p>
                    <router-link
                        :to="{ name: 'Create', params: {'locale': $i18n.locale }}"
                        class="btn btn--primary has--icon has--arrow has--shine"
                    >
                        {{ $t('Commands.StartNow' ) }}
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            class="btn__svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="none"
                            viewBox="0 0 256 256"
                        >
                            <rect
                                width="256"
                                height="256"
                                fill="none"
                            />
                            <line
                                x1="40"
                                y1="128"
                                x2="216"
                                y2="128"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="16"
                            />
                            <polyline
                                points="144 56 216 128 144 200"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="16"
                            />
                        </svg>
                    </router-link>
                </div>
                <div class="landingpage__visual">
                    <img
                        src="/img/content/key-visual.svg"
                        width="570"
                        height="548"
                        alt=""
                    >
                </div>
            </div>

            <div class="section-intro">
                <h2 class="section__headline">
                    {{ $t('Pages.Home.SubHeadline') }}
                </h2>
                <p class="section__text">
                    {{ $t('Pages.Home.SubCopy') }}
                </p>
            </div>

            <div class="benefits">
                <div class="benefit__item">
                    <div class="benefit__visual">
                        <img
                            src="/img/content/create-team.svg"
                            loading="lazy"
                            width="135"
                            height="170"
                            alt=""
                        >
                    </div>
                    <div class="benefit__text">
                        <h3>{{ $t('Pages.Home.Benefits.CreateTeam.Headline') }}</h3>
                        <p>{{ $t('Pages.Home.Benefits.CreateTeam.Copy') }}</p>
                    </div>
                </div>
                <div class="benefit__item">
                    <div class="benefit__visual">
                        <img
                            src="/img/content/availability.svg"
                            loading="lazy"
                            width="277"
                            height="170"
                            alt=""
                        >
                    </div>
                    <div class="benefit__text">
                        <h3>{{ $t('Pages.Home.Benefits.ShareLink.Headline') }}</h3>
                        <p>{{ $t('Pages.Home.Benefits.ShareLink.Copy') }}</p>
                    </div>
                </div>
                <div class="benefit__item">
                    <div class="benefit__visual">
                        <img
                            src="/img/content/together-office.svg"
                            loading="lazy"
                            width="170"
                            height="170"
                            alt=""
                        >
                    </div>
                    <div class="benefit__text">
                        <h3>{{ $t('Pages.Home.Benefits.ShareOffice.Headline') }}</h3>
                        <p>{{ $t('Pages.Home.Benefits.ShareOffice.Copy') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {pageTitleMetaInfo} from "@/i18n/page-title-meta-info";

export default {
    name: "Home",
    metaInfo() {
        return pageTitleMetaInfo(this.$t("Pages.Home.PageTitle"));
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global/config/variables';

.landingpage {
    width: 100%;
    display: grid;
    grid-gap: 3.125rem; // 50px
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    margin: 0 0 $space-lg 0;

    @media (max-width: $mobile-max) {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
    }
}

.landingpage__text {

}

.landingpage__headline {
    margin: 0 0 $space-md 0;
}

.landingpage__copy {
    margin: 0 0 $space-md 0;
}

.landingpage__visual {
    @media (max-width: $mobile-max) {
        padding: 0 1.875rem;
    }
}

.benefits {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.875rem; //30px

    @media (max-width: $mobile-max) {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
    }
}

.benefit__item {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    background: #fff;
    box-shadow: 2px 2px 7px 0 rgba(0,0,0,0.2);
    padding: 30px;
    border-radius: 0.625rem;
}

.benefit__visual {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 170px;
}
</style>
