<template>
    <!--
        https://router.vuejs.org/guide/advanced/transitions.html#per-route-transition
    -->
    <div
        id="app"
        class="app"
    >
        <PageHeader />
        <PageMain />
        <Feedback />
        <PageFooter />
    </div>
</template>

<script>
import Feedback from "@/components/Feedback";
import PageFooter from "@/components/PageFooter";
import PageHeader from "@/components/PageHeader";
import PageMain from "@/components/PageMain";
import {localeToLocaleAndTerritory} from "@/i18n/locale-to-locale-and-territory";

export default {
    name: "App",
    components: {PageMain, Feedback, PageFooter, PageHeader},
    metaInfo() {
        const otherLocales = this.$i18n.availableLocales.filter(locale => locale !== this.$i18n.locale);
        const otherLocaleProperties = otherLocales.map((locale) => (
            { property: "og:locale:alternate", content: localeToLocaleAndTerritory(locale) })
        );

        return {
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                { name: "description", content: this.$t("SEO.MetaDescription") },
                { property: "og:description", content: this.$t("SEO.MetaDescription") },
                { property: "og:locale", content: localeToLocaleAndTerritory(this.$i18n.locale) },
                ...otherLocaleProperties,
                { name: "twitter:description", content: this.$t("SEO.MetaDescription") },
                { name: "robots", content: "index, follow", vmid: "robots" },
            ],
            title: "HyThere",
            titleTemplate: "%s | HyThere"
        };
    }
};
</script>

<style lang="scss">
@import "./assets/scss/global";
</style>

<style scoped lang="scss">
.app {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: 100vh;
}
</style>
