import {getBrowserLocale} from "@/i18n/get-browser-locale";
import {isSupportedLocale} from "@/i18n/is-supported-locale";

export function getStartingLocale() {
    const browserLocale = getBrowserLocale({countryCodeOnly: true});
    // console.debug("GetStartingLocale: Got browser locale " + browserLocale);

    if (isSupportedLocale(browserLocale)) {
        // console.debug("GetStartingLocale: Accepted");
        return browserLocale;
    }

    // console.debug("GetStartingLocale: Declined, using en");
    return "en";
}
