export function localeToLocaleAndTerritory(locale) {
    switch (locale) {
        case "en":
            return "en_GB";

        case "de":
            return "de_DE";
    }

    return `${locale}_${locale}`;
}
