<template>
    <div class="space--top space--bottom">
        <div class="container-lg">
            <div class="content-block">
                <h1>{{ $t('Pages.DataProtection.Headline') }}</h1>
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="$t('Pages.DataProtection.Content')" />
                <!-- eslint-enable -->
            </div>
        </div>
    </div>
</template>

<script>
import {pageTitleMetaInfo} from "@/i18n/page-title-meta-info";

export default {
    name: "DataProtection",
    metaInfo() {
        return pageTitleMetaInfo(this.$t("Pages.DataProtection.PageTitle"));
    }
};
</script>
