<template>
    <div class="space--top space--bottom">
        <template v-if="isCreating">
            <div class="section-intro">
                <h1 class="section__headline">
                    {{ $t('Pages.Create.CreatingTeam') }}
                </h1>
            </div>

            <div
                class="loader"
                aria-hidden="true"
            >
                <div class="loader__animation" />
            </div>
        </template>
        <template v-else>
            <div class="section-intro">
                <h1 class="section__headline">
                    {{ $t('Pages.Create.Headline') }}
                </h1>
            </div>

            <div class="container-xs">
                <form class="form">
                    <!-- error -->
                    <div
                        v-if="submitted"
                        class="alert alert--error"
                    >
                        <div class="alert__message">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                fill="currentColor"
                            >
                                <path
                                    d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                                />
                            </svg>
                            <p>{{ $t('Pages.Create.ErrorMessage') }}</p>
                        </div>
                    </div><!-- ./error -->

                    <!-- teamname -->
                    <div :class="['form__row', { 'is--invalid': submitted && teamNameError }]">
                        <div class="form__field">
                            <label for="create-teamname">
                                <span class="field__label">{{ $t('Concepts.TeamName') }}</span>
                            </label>
                            <input
                                id="create-teamname"
                                v-model="teamName"
                                type="text"
                                class="text-input"
                                required="required"
                            >
                        </div>
                    </div>
                    <!-- ./teamname -->

                    <!-- email -->
                    <div :class="['form__row', { 'is--invalid': submitted && emailError }]">
                        <div class="form__field">
                            <label for="create-email">
                                <span class="field__label">{{ $t('Forms.YourEmail') }}</span>
                            </label>
                            <input
                                id="create-email"
                                v-model="email"
                                type="email"
                                name="email"
                                autocomplete="email"
                                class="text-input"
                                autocapitalize="none"
                                spellcheck="false"
                                required="required"
                            >
                        </div>
                    </div>
                    <!-- ./email -->

                    <!-- name -->
                    <div :class="['form__row', { 'is--invalid': submitted && organizerNameError }]">
                        <div class="form__field">
                            <label for="create-organizer-name">
                                <span class="field__label">{{ $t('Forms.YourName') }}</span>
                            </label>
                            <input
                                id="create-organizer-name"
                                v-model="organizerName"
                                type="text"
                                class="text-input"
                                required="required"
                            >
                        </div>
                    </div>
                    <!-- ./name -->

                    <!-- submit -->
                    <div class="form__submit">
                        <button
                            type="submit"
                            class="btn btn--primary has--icon has--arrow"
                            @click.prevent="createTeam"
                        >
                            {{ $t('Commands.CreateTeam') }}

                            <svg
                                aria-hidden="true"
                                focusable="false"
                                class="btn__svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="none"
                                viewBox="0 0 256 256"
                            >
                                <rect
                                    width="256"
                                    height="256"
                                    fill="none"
                                />
                                <line
                                    x1="40"
                                    y1="128"
                                    x2="216"
                                    y2="128"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="16"
                                />
                                <polyline
                                    points="144 56 216 128 144 200"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="16"
                                />
                            </svg>
                        </button>
                    </div>
                    <!-- ./submit -->
                </form>
            </div>
        </template>
    </div>
</template>

<script>
import {pageTitleMetaInfo} from "@/i18n/page-title-meta-info";
import {isValidEmail} from "@/util/is-valid-email";

export default {
    name: "Create",
    data: () => {
        return {
            email: "",
            isCreating: false,
            submitted: false,
            teamName: "",
            organizerName: ""
        };
    },
    computed: {
        emailError: function() {
            return this.email === "" || !isValidEmail(this.email);
        },
        teamNameError: function() {
            return this.teamName === "";
        },
        organizerNameError: function() {
            return this.organizerName === "";
        }
    },
    metaInfo() {
        return pageTitleMetaInfo(this.$t("Pages.Create.PageTitle"));
    },
    methods: {
        createTeam() {
            this.submitted = true;

            if (this.emailError || this.teamNameError || this.organizerNameError) {
                return;
            }

            this.isCreating = true;
            this.$root.$data.createTeam(this.email, this.$i18n.locale, this.teamName, this.organizerName);
        }
    }
};
</script>
